@import 'urls.scss';
@import 'colors.scss';
@import 'media.queries.scss';

.general-popup {
    width: 100%;
    max-width: 55rem;
    height: 37rem;
    border-radius: 3.2rem;
    padding: 3rem;

    /*div:where(.swal2-container) div:where(.swal2-popup).swal2-loading {
 overflow-y: hidden;*/

    @media (max-width: $tabletXl) {
        padding: 3.2rem;
    }

    .swal2-image {
        margin: 0 auto;
        width: 12rem;

        @media (max-width: $tabletXl) {
            width: 8rem;
        }
    }

    .swal2-title {
        font-size: 3.2rem !important;
        font-family: 'InterBold', sans-serif;
        padding: 0 !important;

        @media (max-width: $tabletXl) {
            font-size: 2.4rem !important;
        }
    }

    .swal2-html-container {
        margin: 0;
        padding: 0;
        font-size: 1.4rem !important;
        color: $loadingText !important;
        font-family: 'InterMedium', sans-serif;
    }

    .swal2-actions {
        margin: 0;
        padding: 0;
    }

    .swal2-confirm, .swal2-cancel {
        margin: 0;
        background: $primaryBlue;
        font-family: 'InterMedium', sans-serif;
        color: $dropDown;
        transition: background 0.7s ease;
        border-radius: 2.4rem;
        padding: 1.2rem 3.2rem;
        font-size: 1.4rem;
    }

    .swal2-confirm:hover, .swal2-cancel:hover {
        background: $companyLogo;
    }
}

// Loader
@keyframes resizeLoadBar {
    0%, 80%, 100% {
        height: 3em;
    }
    40% {
        height: 6em;
    }
}

.loader-popup {
    max-width: 55rem;
    width: 100%;
    height: 100%;
    max-height: 40rem;
    border-radius: 3.2rem;

    @media (max-width: $tabletXl) {
        //max-width: unset;
    }

    .swal2-loader {
        display: none !important;
    }

    .swal2-actions {
        margin: 0 !important;
    }

    .swal2-title, .swal2-html-container {
        margin: 0 !important;
    }

    .swal2-title {
        font-size: 3.2rem !important;
        font-family: 'InterBold', sans-serif;
        padding: 1.5rem !important;
    }

    .swal2-html-container {
        display: flex !important;
        font-size: 1.5rem !important;
        font-family: 'InterMedium', sans-serif;
        padding: 10rem 0 0 0;
    }

    .loader-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .loader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 13rem;
        height: 4rem;
        position: relative;
    }

    .loading-text {
        color: $loadingText;
        text-align: center;
        font-family: 'InterBold', sans-serif;
        font-size: 1.4rem;
        margin-top: 4.8rem;
    }

    .loader span {
        display: block;
        background: $primaryBlue;
        width: 2.2rem;
        height: 4em;
        animation: resizeLoadBar 1s infinite ease-in-out;
        position: relative;
        border-radius: 2rem;
    }

    .loader span:nth-child(1) {
        animation-delay: 0s;
    }

    .loader span:nth-child(2) {
        animation-delay: -0.3s;
    }

    .loader span:nth-child(3) {
        animation-delay: -0.6s;
    }

    .loader span:nth-child(4) {
        animation-delay: -0.9s;
    }

    .loader span:nth-child(5) {
        animation-delay: -1.2s;
    }
}

/*.custom-permission-popup {
    width: 650px;
    height: 300px;
    border-radius: 32px;
    background: white;
    padding: 0 0 1em;

    .swal2-title {
        text-align: start;
        color: $primaryWhite;
        font-size: 1.4rem;
        display: block;
        background: $primaryBlue;
        border-radius: 32px 32px 0 0;
        padding: .8em 1em;
        font-family: 'InterMedium', sans-serif;
        font-weight: unset;
        height: 65px;
    }

    .swal2-html-container {
        text-align: start;
        color: $loadingText;
        font-size: 1rem;
        font-family: 'InterMedium', sans-serif;
    }

    .swal2-confirm, .swal2-cancel {
        border: none;
        cursor: pointer;
        margin: 0;
        transition: background-color 0.7s ease;
        outline: none;
        background: $primaryBlue;
        padding: 12px 32px;
        font-size: 0.875rem;
        font-family: 'InterMedium', sans-serif;
        color: $dropDown;
        border-radius: 24px;
    }

    .swal2-cancel {
        color: $primaryBlue;
        background: transparent;
        border: 1px solid $primaryBlue;
        margin-inline-end: 10px;
    }

    .swal2-cancel:hover {
        background-color: $primaryWhite;
        color: $companyLogo;
        border: 1px solid $companyLogo;
    }

    .swal2-confirm:hover {
        background: $companyLogo;
    }

    .swal2-actions {
        flex-direction: row-reverse;
        width: 100%;
        align-items: end;
        justify-content: end;
        padding: 1em 1.6em .3em;
    }
}*/
