@import 'constants/urls.scss';
@import 'constants/colors.scss';
@import 'constants/media.queries.scss';
@import 'constants/viewport.scss';

@font-face {
    font-family: 'Roboto';
    src: url($fontFamilyRoboto);
}
@font-face {
    font-family: 'RobotoMedium';
    src: url($fontFamilyRobotoMedium);
}
@font-face {
    font-family: 'RobotoBold';
    src: url($fontFamilyRobotoBold);
}
@font-face {
    font-family: 'RobotoItalic';
    src: url($fontFamilyRobotoItalic);
}
@font-face {
    font-family: 'InterRegular';
    src: url($fontFamilyInterRegular);
}
@font-face {
    font-family: 'InterMedium';
    src: url($fontFamilyInterMedium);
}
@font-face {
    font-family: 'InterBold';
    src: url($fontFamilyInterBold);
}
@font-face {
    font-family: 'InterSemiBold';
    src: url($fontFamilyInterSemiBold);
}
@font-face {
    font-family: 'InterExtraBold';
    src: url($fontFamilyInterExtraBold);
}

:root {
    --circle-size: 80%;
    --blending: hard-light;
}

html {
    font-size: 0.521vw; // 10px na desktopu sa sirinom 1920px

    @media (max-width: $tabletXl) {
        font-size: 1.5625vw;
    }

    @media (max-width: $mobileL) {
        font-size: 2.083vw; // 10px na mobilnom na sirini 480px
    }

    @media (max-width: $mobileM) {
        font-size: 3.125vw; // 10px na mobilnom na sirini 320px
    }
}

html, body {
    margin: 0;
    padding: 0;
}

p, h1, h2 {
    font-style: normal;
    line-height: normal;
    margin: 0;
    letter-spacing: -0.02em;
}

button {
    border: none;
    cursor: pointer;
    margin: 0;
    transition: background-color 0.7s ease;
}

input, textarea {
    border: none;
    resize: none;
    padding: 0;
    line-height: normal;
}

input:focus, textarea:focus, button:focus {
    outline: none;
}

button:disabled, input:disabled, textarea:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

// vertical and horizontal scroll
::-webkit-scrollbar {
    width: $fontSize5;
    height: $fontSize5;
}

::-webkit-scrollbar-thumb {
    background-color: $scroll;
    height: $fontSize5;
    border-radius: $fontSize20;
}

.mat-drawer-inner-container {
    overflow: visible !important;
}

.swal2-container {
    @media (max-width: $tabletXl) {
        padding: $mobileFontSize16;
    }
}
